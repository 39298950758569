import colors from "../colors";
import { isMobile } from "react-device-detect";

export default function Hero() {
  return (
    <footer
      style={{
        backgroundColor: colors.pink,
        padding: "12px",
        display: "flex",
        justifyContent: "flex-end",
        color: colors.white,
        marginTop: isMobile ? 24 : 0,
      }}
    >
      <h3 style={{ fontSize: isMobile ? 12 : 16 }}>
        &copy; 2024 Sonorah Records. All rights reserved.
      </h3>
    </footer>
  );
}
