import React, { useState, createContext, useContext } from "react";
import { faSoundcloud, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { isMobile } from "react-device-detect";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import colors from "../colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const tracks = [
  "authors_mix",
  "dj_set_april",
  "gold_era_set_disco",
  "june_dj_set",
];

const trackLinks = [
  {
    youtube: "",
    soundcloud:
      "https://soundcloud.com/norahvalentemakemusic/authors-mix-norah-valente-the-experiment?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
  },
  {
    youtube:
      "https://www.youtube.com/watch?v=dTikvVNS0xQ&ab_channel=NorahValente",
    soundcloud:
      "https://soundcloud.com/norahvalentemakemusic/june-dj-set-2-norah-valente-norahvalentemusic",
  },
  {
    youtube:
      "https://www.youtube.com/watch?v=dzYMCgqLbbY&ab_channel=NorahValente",
    soundcloud:
      "https://soundcloud.com/norahvalentemakemusic/gold-era-set-disco-part-1",
  },
  {
    youtube:
      "https://www.youtube.com/watch?v=5Jd4Xv3mE9I&t=2529s&ab_channel=NorahValente",
    soundcloud:
      "https://soundcloud.com/norahvalentemakemusic/june-dj-set-2-norah-valente-norahvalentemusic",
  },
];

export default function Tracks({ windowWidthDiff }) {
  return (
    <div
      style={{ margin: isMobile ? "24px 24px 0px 24px" : "40px 40px 0px 40px" }}
    >
      <h1
        style={{
          color: colors.pink,
          fontSize: isMobile ? 20 * windowWidthDiff : 24 * windowWidthDiff,
        }}
      >
        Dj sets
      </h1>
      <TracksContent windowWidthDiff={windowWidthDiff} />
    </div>
  );
}

const TracksContentContext = createContext();

const TracksContent = ({ windowWidthDiff }) => {
  const [scrollIndex, setScrollIndex] = useState(0);
  return (
    <TracksContentContext.Provider
      value={{ scrollIndex, setScrollIndex, windowWidthDiff }}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: "hidden",
            marginTop: isMobile ? 12 : 24,
            marginLeft: isMobile
              ? -12 -
                scrollIndex *
                  (220 * windowWidthDiff + 12 + 10 + 8 + 24 * windowWidthDiff)
              : -24 - scrollIndex * 800 * windowWidthDiff,
            transition: "margin-left 300ms",
          }}
        >
          {tracks.map((trackName, index) => (
            <TrackPlayer key={trackName} trackName={trackName} index={index} />
          ))}
        </div>
        {!isMobile && scrollIndex !== 1 && (
          <ScrollerNext setScrollIndex={setScrollIndex} />
        )}

        {!isMobile && scrollIndex !== 0 && (
          <ScrollerPrevious
            windowWidthDiff={windowWidthDiff}
            setScrollIndex={setScrollIndex}
          />
        )}
      </div>
    </TracksContentContext.Provider>
  );
};

const TrackPlayer = ({ trackName, index }) => {
  const [isHoving, setIsHoving] = useState(false);
  const { windowWidthDiff, setScrollIndex, scrollIndex } =
    useContext(TracksContentContext);

  return (
    <div
      style={{ display: "flex" }}
      onMouseEnter={() => setIsHoving(true)}
      onMouseLeave={() => setIsHoving(false)}
    >
      <div
        style={{
          backgroundImage: `url(/imgs/tracks/${trackName}.jpg)`,
          backgroundSize: "cover",
          // backgroundPosition: "right",
          height: isMobile ? 120 * windowWidthDiff : 280 * windowWidthDiff,
          width: isMobile
            ? 220 * windowWidthDiff
            : isHoving
            ? 500 * windowWidthDiff - 40 * windowWidthDiff - 8 - 12
            : 500 * windowWidthDiff,
          flexShrink: 0,
          marginLeft: isMobile ? 12 : 24,
          transition: "width 100ms",
          position: "relative",
        }}
      >
        {isMobile && scrollIndex !== 3 && (
          <ScrollerNext
            windowWidthDiff={windowWidthDiff}
            setScrollIndex={setScrollIndex}
          />
        )}

        {isMobile && scrollIndex !== 0 && (
          <ScrollerPrevious
            windowWidthDiff={windowWidthDiff}
            setScrollIndex={setScrollIndex}
          />
        )}
      </div>

      {(isMobile || isHoving) && (
        <div style={{ marginLeft: 8 }}>
          {trackLinks[index].youtube && (
            <SocialMedia
              icon={faYoutube}
              isFirst
              windowWidthDiff={windowWidthDiff}
              link={trackLinks[index].youtube}
            />
          )}
          {trackLinks[index].soundcloud && (
            <SocialMedia
              icon={faSoundcloud}
              windowWidthDiff={windowWidthDiff}
              link={trackLinks[index].soundcloud}
            />
          )}
        </div>
      )}
    </div>
  );
};

const SocialMedia = ({ icon, link, isFirst = false, windowWidthDiff }) => {
  const [isHoving, setIsHoving] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHoving(true)}
      onMouseLeave={() => setIsHoving(false)}
      style={{
        padding: 6,
        backgroundColor: isHoving ? colors.pink : colors.gray,
        borderRadius: 8,
        marginTop: isFirst ? 0 : 8,
        cursor: "pointer",
        transition: "background 300ms",
        display: "flex",
        alignItems: "center",
      }}
      onClick={() => {
        window.open(link, "_blank");
      }}
    >
      <FontAwesomeIcon
        color={isHoving ? colors.white : colors.pink}
        icon={icon}
        style={{
          height: isMobile ? 24 * windowWidthDiff : 40 * windowWidthDiff,
          width: isMobile ? 24 * windowWidthDiff : 40 * windowWidthDiff,
        }}
      />
    </div>
  );
};

const ScrollerNext = () => {
  const { windowWidthDiff, setScrollIndex } = useContext(TracksContentContext);

  return (
    <div
      style={{
        position: "absolute",
        right: -1,
        top: -1,
        bottom: 0,
        width: isMobile ? 32 * windowWidthDiff : 48 * windowWidthDiff,
        background: `linear-gradient(to left, ${colors.black}, transparent)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      {...(!isMobile && {
        onClick: () => setScrollIndex((oldScroll) => oldScroll + 1),
      })}
    >
      <FontAwesomeIcon
        icon={faChevronRight}
        color={colors.white}
        style={{
          width: isMobile ? 32 * windowWidthDiff : 40 * windowWidthDiff,
          height: isMobile ? 32 * windowWidthDiff : 40 * windowWidthDiff,
        }}
        {...(isMobile && {
          onClick: () => setScrollIndex((oldScroll) => oldScroll + 1),
        })}
      />
    </div>
  );
};

const ScrollerPrevious = () => {
  const { windowWidthDiff, setScrollIndex } = useContext(TracksContentContext);

  return (
    <div
      style={{
        position: "absolute",
        left: isMobile ? -1 : -40,
        top: -1,
        bottom: 0,
        width: isMobile ? 32 * windowWidthDiff : 48 * windowWidthDiff,
        background: `linear-gradient(to right, ${colors.black}, transparent)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => setScrollIndex((oldScroll) => oldScroll - 1)}
    >
      <FontAwesomeIcon
        icon={faChevronLeft}
        color={colors.white}
        style={{
          width: isMobile ? 32 * windowWidthDiff : 40 * windowWidthDiff,
          height: isMobile ? 32 * windowWidthDiff : 40 * windowWidthDiff,
        }}
      />
    </div>
  );
};
