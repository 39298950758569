const translations = {
  pt: {
    description1:
      "Com um som único e envolvente, Norah conquista públicos ao redor do mundo com suas músicas autênticas e emocionantes. Suas produções originais, marcadas por melodias cativantes, refletem sua paixão pela música e seu compromisso em criar arte que toca o coração das pessoas.",

    description2:
      "Cada canção é cuidadosamente trabalhada para transmitir emoções genuínas e criar uma conexão especial com seus ouvintes. A energia magnética de Norah e sua habilidade em se comunicar por meio da música fazem dela uma artista incomparável. Ela entrega performances marcantes e memoráveis, que encantam e inspiram.",

    mySocialMedias: "minhas redes sociais",

    contactForm: {
      title: "Contacte-me",
      description: "ou entre em contato: @norahvalenterecords@icloud.com",
      name: "me chamo",
      email: "email",
      message: "mensagem",
      send: "enviar",
    },
  },

  en: {
    description1:
      "With a unique and engaging sound, Norah captivates audiences around the world with her authentic and emotional music. Her original productions, marked by catchy melodies, reflect her passion for music and her commitment to creating art that touches people's hearts.",

    description2:
      "Each song is carefully crafted to convey genuine emotions and create a special connection with her listeners. Norah's magnetic energy and her ability to communicate through music make her an incomparable artist. She delivers remarkable and memorable performances that delight and inspire.",

    mySocialMedias: "my social media",

    contactForm: {
      title: "Contact Me",
      description: "or get in touch: @norahvalenterecords@icloud.com",
      name: "name",
      email: "email",
      message: "message",
      send: "send",
    },
  },

  es: {
    description1:
      "Con un sonido único y envolvente, Norah conquista al público de todo el mundo con su música auténtica y emocional. Sus producciones originales, marcadas por melodías pegadizas, reflejan su pasión por la música y su compromiso de crear arte que toque el corazón de las personas.",

    description2:
      "Cada canción está cuidadosamente elaborada para transmitir emociones genuinas y crear una conexión especial con sus oyentes. La energía magnética de Norah y su habilidad para comunicarse a través de la música la convierten en una artista incomparable. Ofrece actuaciones notables e inolvidables que encantan e inspiran.",

    mySocialMedias: "mis redes sociales",

    contactForm: {
      title: "Contáctame",
      description: "o ponte en contacto: @norahvalenterecords@icloud.com",
      name: "nombre",
      email: "correo electrónico",
      message: "mensaje",
      send: "enviar",
    },
  },

  fr: {
    description1:
      "Avec un son unique et captivant, Norah conquiert les publics du monde entier avec sa musique authentique et émouvante. Ses productions originales, marquées par des mélodies accrocheuses, reflètent sa passion pour la musique et son engagement à créer de l'art qui touche le cœur des gens.",

    description2:
      "Chaque chanson est soigneusement élaborée pour transmettre des émotions authentiques et créer une connexion spéciale avec ses auditeurs. L'énergie magnétique de Norah et sa capacité à communiquer à travers la musique font d'elle une artiste incomparable. Elle offre des performances remarquables et mémorables qui enchantent et inspirent.",

    mySocialMedias: "mes réseaux sociaux",

    contactForm: {
      title: "Contactez-moi",
      description: "ou contactez : @norahvalenterecords@icloud.com",
      name: "nom",
      email: "email",
      message: "message",
      send: "envoyer",
    },
  },

  de: {
    description1:
      "Mit einem einzigartigen und fesselnden Sound erobert Norah mit ihrer authentischen und emotionalen Musik Publikum auf der ganzen Welt. Ihre Originalproduktionen, geprägt von eingängigen Melodien, spiegeln ihre Leidenschaft für Musik und ihr Engagement wider, Kunst zu schaffen, die die Herzen der Menschen berührt.",

    description2:
      "Jeder Song ist sorgfältig gestaltet, um echte Emotionen zu vermitteln und eine besondere Verbindung zu ihren Zuhörern herzustellen. Norahs magnetische Energie und ihre Fähigkeit, durch Musik zu kommunizieren, machen sie zu einer unvergleichlichen Künstlerin. Sie liefert bemerkenswerte und unvergessliche Auftritte, die begeistern und inspirieren.",

    mySocialMedias: "meine sozialen Medien",

    contactForm: {
      title: "Kontaktiere mich",
      description: "oder melde dich: @norahvalenterecords@icloud.com",
      name: "Name",
      email: "E-Mail",
      message: "Nachricht",
      send: "senden",
    },
  },

  it: {
    description1:
      "Con un suono unico e coinvolgente, Norah conquista il pubblico di tutto il mondo con la sua musica autentica ed emotiva. Le sue produzioni originali, caratterizzate da melodie accattivanti, riflettono la sua passione per la musica e il suo impegno nel creare arte che tocca il cuore delle persone.",

    description2:
      "Ogni canzone è accuratamente lavorata per trasmettere emozioni genuine e creare una connessione speciale con i suoi ascoltatori. L'energia magnetica di Norah e la sua capacità di comunicare attraverso la musica la rendono un'artista incomparabile. Offre performance notevoli e memorabili che incantano e ispirano.",

    mySocialMedias: "i miei social media",

    contactForm: {
      title: "Contattami",
      description: "o contatta: @norahvalenterecords@icloud.com",
      name: "nome",
      email: "email",
      message: "messaggio",
      send: "invia",
    },
  },

  ru: {
    description1:
      "С уникальным и захватывающим звучанием Нора покоряет публику по всему миру своей аутентичной и эмоциональной музыкой. Ее оригинальные произведения, отмеченные запоминающимися мелодиями, отражают ее страсть к музыке и стремление создавать искусство, которое трогает сердца людей.",

    description2:
      "Каждая песня тщательно создана, чтобы передать искренние эмоции и создать особую связь со слушателями. Магнетическая энергия Норы и ее способность общаться через музыку делают ее несравненной артисткой. Она дарит яркие и незабываемые выступления, которые восхищают и вдохновляют.",

    mySocialMedias: "мои социальные сети",

    contactForm: {
      title: "Свяжитесь со мной",
      description: "или свяжитесь: @norahvalenterecords@icloud.com",
      name: "имя",
      email: "электронная почта",
      message: "сообщение",
      send: "отправить",
    },
  },

  zh: {
    description1:
      "凭借独特而引人入胜的声音，诺拉以其真实而富有情感的音乐征服了全球的听众。她的原创作品以朗朗上口的旋律为特色，反映了她对音乐的热爱和创造触动人心艺术的承诺。",

    description2:
      "每首歌曲都经过精心制作，旨在传达真挚的情感，与听众建立特殊的联系。诺拉的磁性能量和通过音乐进行交流的能力使她成为一位无与伦比的艺术家。她带来令人瞩目且难忘的表演，令人陶醉和鼓舞。",

    mySocialMedias: "我的社交媒体",

    contactForm: {
      title: "联系我",
      description: "或联系：@norahvalenterecords@icloud.com",
      name: "姓名",
      email: "电子邮件",
      message: "消息",
      send: "发送",
    },
  },

  ja: {
    description1:
      "独自で魅力的なサウンドで、ノラはその本格的で感情的な音楽で世界中の観客を魅了します。キャッチーなメロディーを特徴とする彼女のオリジナル作品は、音楽への情熱と人々の心に響く芸術を創造する彼女のコミットメントを反映しています。",

    description2:
      "各曲は真の感情を伝え、リスナーと特別なつながりを生み出すために慎重に作られています。ノラの磁気的なエネルギーと音楽を通じてコミュニケーションする能力は、彼女を比類のないアーティストにしています。彼女は魅了し、インスピレーションを与える注目すべき記憶に残るパフォーマンスを提供します。",

    mySocialMedias: "私のソーシャルメディア",

    contactForm: {
      title: "お問い合わせ",
      description: "または連絡先：@norahvalenterecords@icloud.com",
      name: "名前",
      email: "メール",
      message: "メッセージ",
      send: "送信",
    },
  },

  ko: {
    description1:
      "독특하고 매력적인 사운드로 노라는 진정성 있고 감성적인 음악으로 전 세계 관객을 사로잡습니다. 캐치한 멜로디가 특징인 그녀의 오리지널 프로덕션은 음악에 대한 열정과 사람들의 마음을 감동시키는 예술을 창조하려는 그녀의 헌신을 반영합니다。",

    description2:
      "각 곡은 진정한 감정을 전달하고 청취자와 특별한 연결을 만들기 위해 신중하게 제작되었습니다. 노라의 매력적인 에너지와 음악을 통해 소통하는 능력은 그녀를 비할 데 없는 아티스트로 만듭니다. 그녀는 감탄과 영감을 주는 주목할 만하고 잊을 수 없는 공연을 선사합니다。",

    mySocialMedias: "나의 소셜 미디어",

    contactForm: {
      title: "문의하기",
      description: "또는 연락하기: @norahvalenterecords@icloud.com",
      name: "이름",
      email: "이메일",
      message: "메시지",
      send: "보내기",
    },
  },

  ar: {
    description1:
      "بصوت فريد وجذاب، تفوز نورا بالجماهير حول العالم بموسيقاها الأصيلة والعاطفية. تعكس إنتاجاتها الأصلية، التي تتميز بألحان جذابة، شغفها بالموسيقى والتزامها بخلق فن يلامس قلوب الناس.",

    description2:
      "كل أغنية مصنوعة بعناية لنقل مشاعر حقيقية وخلق اتصال خاص مع مستمعيها. تجعلها الطاقة المغناطيسية لنورا وقدرتها على التواصل من خلال الموسيقى فنانة لا مثيل لها. تقدم عروضًا مميزة ولا تُنسى تُسحر وتلهم.",

    mySocialMedias: "وسائل التواصل الاجتماعي الخاصة بي",

    contactForm: {
      title: "اتصل بي",
      description: "أو تواصل: @norahvalenterecords@icloud.com",
      name: "الاسم",
      email: "البريد الإلكتروني",
      message: "الرسالة",
      send: "إرسال",
    },
  },
};

export default translations;
