import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faBackward,
  faForward,
  faMusic,
} from "@fortawesome/free-solid-svg-icons";

import {
  faSoundcloud,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { Howl } from "howler";

import { isMobile } from "react-device-detect";

import colors from "../colors";

const tracks = [
  "openAParadise",
  "escape",
  "beatOfHeart",
  "aintSunshine",
  "2Alive",
];

const tracksNames = [
  "Open a Paradise",
  "Escape",
  "Beat of Heart",
  "Ain't no Sunshine",
  "Alive",
];

const tracksLinks = [
  {
    youtube:
      "https://music.youtube.com/watch?v=bPuEWK3tiJE&si=jIwBjFQtfVkxO35r",
    spotify:
      "https://open.spotify.com/intl-pt/track/0vNGNObq5L2o86QW9ubVyH?si=5b86d6fe18f841f9",
    soundcloud:
      "https://soundcloud.com/norahvalentemusic/openaparadisenorahvalentemusic",
    appleMusic:
      "https://music.apple.com/us/album/open-a-paradise-single/1755964611",
  },
  {
    youtube:
      "https://music.youtube.com/watch?v=7NwgOgzCPDE&si=7nLZFnCTupnLNT3V",
    spotify:
      "https://open.spotify.com/intl-pt/track/6whp8qnXgIgiE66InIj6mS?si=ab6354845c9a4197",
    soundcloud:
      "https://soundcloud.com/norahvalentemakemusic/escape?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    appleMusic: "https://music.apple.com/us/album/escape-single/1748247734",
  },
  {
    youtube:
      "https://music.youtube.com/watch?v=lJBySW80JmM&si=1OBzZhnvf-IEcg7Y",
    spotify:
      "https://open.spotify.com/intl-pt/track/3CA7h0nTXS47GojQ6GYhn3?si=45494a3b4abf4a58",
    soundcloud:
      "https://soundcloud.com/norahvalentemakemusic/norah-valente-arn-beat-of-heart-extended?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    appleMusic:
      "https://music.apple.com/us/album/beat-of-heart-single/1745843273",
  },
  {
    youtube:
      "https://music.youtube.com/watch?v=CRh8NjQ8Gqw&si=VeBvozOCpbisINXT",
    spotify:
      "https://open.spotify.com/intl-pt/track/2NaolHEJfXGbeoiFEn4TNe?si=5c77a94c2f594fda",
    soundcloud:
      "https://soundcloud.com/norahvalentemakemusic/aint-no-sunshine-1?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    appleMusic:
      "https://music.apple.com/us/album/aint-no-sunhine-single/1745604527",
  },
  {
    youtube:
      "https://music.youtube.com/watch?v=5GHANjdjkck&si=tIboQ4HwkXcj7kL8",
    spotify:
      "https://open.spotify.com/intl-pt/track/5lCMrbilB8CUN2IjneUwBi?si=de06d7320e334ccf",
    soundcloud:
      "https://soundcloud.com/norahvalentemakemusic/alive-norah-valente-my-pop-rock-life-v6?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    appleMusic: "https://music.apple.com/us/album/alive-single/1748071171",
  },
];

export default function Hero({ windowWidthDiff }) {
  const [cardsMode, setCardsMode] = useState("halo");
  const hasChangedMode = useRef(null);
  const sliderRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setCardsMode("open");
      setTimeout(() => {
        hasChangedMode.current = true;
      }, animationTimer);
    }, 1250);
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 60,
        width: "100%",
        // maxWidth: "100%",
        overflow: "hidden",
        height: `calc(100vh + ${isMobile ? 50 : 140 * windowWidthDiff}px)`,
        textAlign: "center",
        position: "relative",
        backgroundImage: "url(/imgs/hero.png)",
        backgroundSize: "cover",
      }}
    >
      {/* <AngelCrown /> */}
      <Slider
        mode={cardsMode}
        hasChangedMode={hasChangedMode}
        sliderRef={sliderRef}
        windowWidthDiff={windowWidthDiff}
      />
      {/* <Banner /> */}
    </div>
  );
}

const CARDS_QTTY = 10;
const baseRotation = 360 / CARDS_QTTY;

const animationTimer = 1000;

const CARD_HEIGHT = 225;
const CARD_WIDTH = 180;
const SliderContext = createContext();

const Slider = ({ mode, hasChangedMode, sliderRef, windowWidthDiff }) => {
  const [selectedPos, setSelectedPos] = useState(1);
  const [delayedSelectedPos, setDelayedSelectedPos] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0); // Progress in percentage
  const hasOpened = useRef(false);

  const sound = useMemo(() => {
    return new Howl({
      src: `musics/${tracks[selectedPos - 1]}.mp3`,
      html5: true,
    });
  }, [selectedPos]);

  useEffect(() => {
    sound.stop();
    if (mode !== "halo") {
      setTimeout(() => {
        setDelayedSelectedPos(selectedPos);
        sound.play();
      }, animationTimer);
    }

    return () => {
      sound.stop();
    };
  }, [selectedPos]);

  useEffect(() => {
    const haloInterval = setInterval(() => {
      setSelectedPos((pos) => pos + 1);
    }, 300);

    if (mode !== "halo" && !hasOpened.current) {
      clearInterval(haloInterval);

      if (sliderRef.current) {
        const { top, height } = sliderRef.current.getBoundingClientRect();
        window.scrollTo({
          top: top + height / 1.2,
          behavior: "smooth",
        });
      }

      setSelectedPos(1);
      hasOpened.current = true;
    }

    return () => clearInterval(haloInterval);
  }, [mode]);

  useEffect(() => {
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleEnd = () => {
      setSelectedPos((oldPos) => {
        if (oldPos === 5) return 1;
        return oldPos + 1;
      }, []);
    };

    sound.on("play", handlePlay);
    sound.on("pause", handlePause);
    sound.on("stop", handlePause);
    sound.on("end", handleEnd);

    return () => {
      sound.off("play", handlePlay);
      sound.off("pause", handlePause);
      sound.off("stop", handlePause);
      sound.off("end", handleEnd);
    };
  }, [sound]);

  useEffect(() => {
    let animationFrameId;

    const updateProgress = () => {
      const currentTime = sound.seek();
      const duration = sound.duration();
      const percentage = (currentTime / duration) * 100;
      setProgress(percentage);

      animationFrameId = requestAnimationFrame(updateProgress);
    };

    updateProgress();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [isPlaying, sound]);

  return (
    <SliderContext.Provider
      value={{
        delayedSelectedPos,
        selectedPos,
        setSelectedPos,
        hasChangedMode,
        mode,
        windowWidthDiff,
        sound,
        isPlaying,
        progress,
      }}
    >
      <div
        ref={sliderRef}
        style={{
          position: "absolute",
          width:
            mode === "halo"
              ? 100 * windowWidthDiff
              : CARD_WIDTH * windowWidthDiff,
          height:
            mode === "halo"
              ? 125 * windowWidthDiff
              : CARD_HEIGHT * windowWidthDiff,
          // top: mode === "halo" ? 32 : 0,
          left: `calc(50% - ${
            mode === "halo"
              ? 100 * windowWidthDiff
              : CARD_WIDTH * windowWidthDiff
          })`,
          transformStyle: "preserve-3d",
          transform: `perspective(${1000 * windowWidthDiff}px) rotateY(${-(
            baseRotation * selectedPos
          )}deg)`,
          transition: "transform 1000ms",
          // zIndex: 15,
        }}
      >
        <img
          src="/imgs/norah.png"
          alt="this is me, Norah Valente"
          style={{
            position: "absolute",
            left: "50%",
            transform: `translate(-50%, 0) rotateY(${
              baseRotation * selectedPos
            }deg`,
            height: "95vh",
            width: "auto",
            transition: "transform 1000ms",
            zIndex: 10,
          }}
        />
        <Card pos={1} />
        <Card pos={2} />
        <Card pos={3} />
        <Card pos={4} />
        <Card pos={5} />
      </div>
    </SliderContext.Provider>
  );
};

const SocialMedias = ({ pos }) => {
  return (
    <div
      style={{
        position: "absolute",
        right: isMobile ? undefined : -28 - 6,
        bottom: isMobile ? -40 - 8 : undefined,
        display: "flex",
        flexDirection: isMobile ? "row" : "column",
      }}
    >
      {tracksLinks[pos].youtube && (
        <SocialMedia icon={faYoutube} isFirst link={tracksLinks[pos].youtube} />
      )}
      {tracksLinks[pos].spotify && (
        <SocialMedia icon={faSpotify} link={tracksLinks[pos].spotify} />
      )}
      {tracksLinks[pos].soundcloud && (
        <SocialMedia icon={faSoundcloud} link={tracksLinks[pos].soundcloud} />
      )}
      {tracksLinks[pos].appleMusic && (
        <SocialMedia icon={faMusic} link={tracksLinks[pos].appleMusic} />
      )}

      {/* <SocialMedia icon={faShare} /> */}
    </div>
  );
};

export const SocialMedia = ({ icon, link, isFirst = false }) => {
  const [isHoving, setIsHoving] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHoving(true)}
      onMouseLeave={() => setIsHoving(false)}
      style={{
        padding: isMobile ? 8 : 4,
        backgroundColor: isHoving ? colors.pink : colors.gray,
        borderRadius: 4,
        marginTop: isMobile ? 0 : isFirst ? 0 : 8,
        marginLeft: isMobile ? (isFirst ? 0 : 8) : 0,
        cursor: "pointer",
        transition: "background 300ms",
      }}
      onClick={() => {
        window.open(link, "_blank");
      }}
    >
      <FontAwesomeIcon
        color={isHoving ? colors.white : colors.pink}
        icon={icon}
        size={isMobile ? "xl" : "1x"}
      />
    </div>
  );
};

const Card = ({ pos }) => {
  const [rotation, setRotation] = useState(0);
  const [isHoving, setIsHoving] = useState(false);

  useEffect(() => {
    setRotation(pos * (360 / CARDS_QTTY));
  }, [pos]);

  const {
    delayedSelectedPos,
    selectedPos,
    setSelectedPos,
    hasChangedMode,
    mode,
    windowWidthDiff,
    progress,
    isPlaying,
  } = useContext(SliderContext);

  return (
    <div
      style={{
        position: "absolute",
        top:
          mode === "halo"
            ? 25 * windowWidthDiff
            : isMobile
            ? 250 * windowWidthDiff
            : 200 * windowWidthDiff,
        transform: `rotateY(${rotation}deg) rotateX(${
          mode === "halo" ? 6 : 0
        }deg) translateZ(${
          mode === "halo"
            ? 160 * windowWidthDiff
            : isMobile
            ? 300 * windowWidthDiff
            : 550 * windowWidthDiff
        }px)`,
        zIndex:
          selectedPos === pos
            ? 20
            : Math.floor(Math.cos(rotation * (Math.PI / 180)) * 10),
        display: "flex",
        flexDirection: "column",
        transition: `transform ${animationTimer}ms, box-shadow 300ms, top 300ms`,
        boxShadow:
          pos === (hasChangedMode.current ? delayedSelectedPos : selectedPos) &&
          mode !== "halo"
            ? "0 0 25px rgba(255, 20, 147, 0.7)"
            : "",
        width:
          mode === "halo"
            ? 100 * windowWidthDiff
            : CARD_WIDTH * windowWidthDiff,
        height:
          mode === "halo"
            ? 125 * windowWidthDiff
            : CARD_HEIGHT * windowWidthDiff,
      }}
    >
      {pos === (hasChangedMode.current ? delayedSelectedPos : selectedPos) &&
        mode !== "halo" && <SocialMedias pos={pos - 1} />}

      <div
        style={{
          position: "relative",
          cursor: "pointer",
          width:
            mode === "halo"
              ? 100 * windowWidthDiff
              : CARD_WIDTH * windowWidthDiff,
          height:
            mode === "halo"
              ? 125 * windowWidthDiff
              : CARD_HEIGHT * windowWidthDiff,
        }}
        onMouseEnter={() => setIsHoving(true)}
        onMouseLeave={() => setIsHoving(false)}
      >
        {!isMobile &&
          isPlaying &&
          pos === (hasChangedMode.current ? delayedSelectedPos : selectedPos) &&
          !isHoving &&
          mode !== "halo" && <ShowMusicControllersPc />}

        {mode !== "halo" &&
          isPlaying &&
          isMobile &&
          (hasChangedMode.current ? delayedSelectedPos : selectedPos) ===
            pos && <MusicControllerMobile />}

        <img
          src={`/imgs/musics/${tracks[pos - 1]}.jpg`}
          alt="card"
          style={{ width: "100%", height: "100%" }}
        />

        {!isPlaying
          ? selectedPos === pos && mode !== "halo" && <PlayPauseHover />
          : isHoving &&
            selectedPos === pos &&
            mode !== "halo" &&
            !isMobile && <PlayPauseHover />}
        {selectedPos - pos === -1 && mode !== "halo" && (
          <NextPreviousHover setSelectedPos={setSelectedPos} />
        )}
        {selectedPos - pos === 1 && mode !== "halo" && (
          <NextPreviousHover setSelectedPos={setSelectedPos} previous />
        )}
      </div>

      {mode !== "halo" &&
        (hasChangedMode.current ? delayedSelectedPos : selectedPos) === pos && (
          <div
            style={{
              background: colors.gray,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "6px 12px 8px 6px",

              color: colors.white,
              // ...musicDataSyles,
            }}
          >
            <h3 style={{ fontSize: 12 }}>{tracksNames[pos - 1]}</h3>
            <MusicBar barPercent={progress} />
          </div>
        )}
    </div>
  );
};

const ShowMusicControllersPc = () => {
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        right: 0,
        margin: "6px 8px",
      }}
    >
      <FontAwesomeIcon icon={faPlay} color={colors.white} size="1x" />
      <FontAwesomeIcon
        icon={faPause}
        style={{ marginLeft: 4 }}
        color={colors.white}
      />
    </div>
  );
};

const MusicControllerMobile = () => {
  const { delayedSelectedPos, setSelectedPos, isPlaying, sound } =
    useContext(SliderContext);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div
        style={{
          flex: 0.2,
          background:
            delayedSelectedPos !== 1
              ? `linear-gradient(to right, ${colors.black}, transparent)`
              : "",
          display: "flex",
          alignItems: "center",
          paddingLeft: delayedSelectedPos !== 1 ? 6 : 0,
        }}
        onClick={() => {
          setSelectedPos((oldPos) => {
            if (oldPos !== 1) return setSelectedPos(oldPos - 1);
            return oldPos;
          });
        }}
      >
        {delayedSelectedPos !== 1 && (
          <FontAwesomeIcon
            icon={faBackward}
            size="1x"
            color={colors.white}
            style={{ width: 24, height: 24 }}
          />
        )}
      </div>
      <div
        style={{
          flex: 0.6,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          if (isPlaying) sound.pause();
          else sound.play();
        }}
      >
        <FontAwesomeIcon
          icon={isPlaying ? faPause : faPlay}
          color={colors.white}
          style={{ width: 32, height: 32 }}
        />
      </div>
      <div
        style={{
          flex: 0.2,
          background:
            delayedSelectedPos !== 5
              ? `linear-gradient(to left, ${colors.black}, transparent)`
              : "",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingRight: delayedSelectedPos !== 5 ? 6 : 0,
        }}
        onClick={() => {
          setSelectedPos((oldPos) => {
            if (oldPos !== 5) return setSelectedPos(oldPos + 1);
            return oldPos;
          });
        }}
      >
        {delayedSelectedPos !== 5 && (
          <FontAwesomeIcon
            icon={faForward}
            style={{ width: 24, height: 24 }}
            color={colors.white}
          />
        )}
      </div>
    </div>
  );
};

const NextPreviousHover = ({ previous = false, setSelectedPos }) => {
  const [isHoving, setIsHoving] = useState(false);

  return (
    <div
      style={{
        backgroundColor: "rgba(20,2,12,.3)",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 15,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onMouseEnter={() => setIsHoving(true)}
      onMouseLeave={() => setIsHoving(false)}
      onClick={() => {
        let nextPositionIs = previous ? -1 : 1;
        setSelectedPos((oldPos) => oldPos + nextPositionIs);
      }}
    >
      <FontAwesomeIcon
        icon={previous ? faBackward : faForward}
        style={{
          width: isHoving ? 48 : 40,
          height: isHoving ? 48 : 40,
          transition: "width 300ms, height 300ms",
        }}
        color={colors.white}
      />
    </div>
  );
};

const PlayPauseHover = () => {
  const { isPlaying, sound } = useContext(SliderContext);

  return (
    <div
      style={{
        backgroundColor: "rgba(20,2,12,.3)",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 15,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        if (isPlaying) {
          sound.pause();
        } else {
          sound.play();
        }
      }}
    >
      <FontAwesomeIcon
        icon={isPlaying ? faPause : faPlay}
        style={{
          width: 40,
          height: 40,
        }}
        color={colors.white}
      />
    </div>
  );
};

const MusicBar = ({ barPercent }) => {
  const [isHoving, setIsHoving] = useState(false);
  const { sound } = useContext(SliderContext);
  const barRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const updateProgress = (e) => {
    const bar = barRef.current.getBoundingClientRect();
    const clickPosition = e.clientX - bar.left;
    const clickPercent = (clickPosition / bar.width) * 100;

    const validPercent = Math.min(100, Math.max(0, clickPercent));

    const newTime = (validPercent / 100) * sound.duration();
    sound.seek(newTime);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    updateProgress(e);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      updateProgress(e);
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
    }
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div
      ref={barRef}
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: 4,
        marginTop: 6,
        cursor: "pointer",
      }}
      onMouseEnter={() => setIsHoving(true)}
      onMouseLeave={() => setIsHoving(false)}
      onMouseDown={handleMouseDown}
    >
      <div
        style={{
          height: "100%",
          width: barPercent + "%",
          borderRadius: barPercent === 100 ? "2px" : "2px 0 0 2px",
          background: colors.pink,
          position: "absolute",
          // transition: "width 200ms ease",
        }}
      />

      {isHoving && (
        <div
          style={{
            height: 8,
            width: 8,
            borderRadius: "50%",
            backgroundColor: colors.pink,
            position: "absolute",
            left: `${barPercent}%`,
            transform: "translateX(-50%)",
            // transition: "left 200ms ease",
          }}
        />
      )}

      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: colors.white,
          borderRadius: 2,
        }}
      />
    </div>
  );
};
