import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

import Hero from "./components/Hero";
import Tracks from "./components/Tracks";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  const [windowWidthDiff, setWindowWidthDiff] = useState(
    isMobile ? window.screen.width / 300 : window.innerWidth / 1440
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidthDiff(window.innerWidth / 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div style={{}}>
      <Hero windowWidthDiff={windowWidthDiff} />
      <Tracks windowWidthDiff={windowWidthDiff} />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
